import React, { Component } from 'react';

import { ComponentArg } from '../../types/component-arg';
import { defaultChildren } from '../generic/default-children';
import { key } from '../util/key';
import { ErrorTypes } from '../../redux/show-error';
import { ShowErrorInstance } from '../../singleton';
import { WithTranslation, withTranslation } from 'react-i18next';
import ScreenReaderOnly from '../util/screenReaderOnly';
import { getAppConfiguration } from '../../redux/store';

class ExtLink1 extends Component<ComponentArg & WithTranslation> {

    constructor(p: ComponentArg & WithTranslation) {
        super(p);
        this.extractExternalUrl();
    }

    private externalUrl?: string;

    private extractExternalUrl() {
        const { node } = this.props;
        if (!node || !node.a) {
            return;
        }

        const docIdValue = 'docid-';
        const idValue = '/id-';

        const extLinkType = node.a['ext-link-type'];
        if (extLinkType === 'uri') {
            this.externalUrl = node.a['xlink:href'];
        } else if (extLinkType === 'docref') {
            const xlinkRef = node.a['xlink:href'];
            if (xlinkRef.startsWith(docIdValue)) {
                let substringLength = xlinkRef.indexOf(idValue) > -1 ? xlinkRef.indexOf(idValue) : 0;
                const productId =  substringLength === 0  ? xlinkRef.substring(docIdValue.length)
                    : xlinkRef.substring(docIdValue.length, substringLength);

                let elementId: string | undefined;
                if (xlinkRef.indexOf(idValue) > -1) {
                    elementId = xlinkRef.substring(xlinkRef.indexOf(idValue) + 1);
                }

                let url = `${getAppConfiguration().standardsViewerWebAppUrl}/product/${productId}`;
                if (elementId) {
                    url += `#${elementId}`;
                }

                this.externalUrl = url;
            }
        }
    }

    render() {
        return this.externalUrl ? this.renderExistingA() : this.renderEmptyA();
    }

    renderExistingA(): JSX.Element {
        const { t } = this.props;

        return (
            <a
                target="_blank"
                rel="noopener noreferrer"
                href={this.externalUrl}
                className="link"
                key={key(this.props)}>
                {defaultChildren(this.props)}
                <ScreenReaderOnly>{t('External link')}</ScreenReaderOnly>
            </a>
        );
    }

    renderEmptyA(): JSX.Element {
        // @ts-ignore
        const onClick = (e: MouseEvent<HTMLAnchorElement, MouseEvent>) => {
            e.preventDefault();
            ShowErrorInstance.Fn(ErrorTypes.UrlNotFound);
        };

        return (<a
            target="_blank"
            rel="noopener noreferrer"
            href="/"
            className="link"
            onClick={onClick}
            key={key(this.props)}>
            {defaultChildren(this.props)}
        </a>
        );
    }
}

const ExtLink = withTranslation()(ExtLink1);

export { ExtLink };
