import * as React from 'react';
import { useTranslation } from 'react-i18next';

export interface RequirementInfoProps {
    reqNr: string;
    vocabTerm: string;    
}

const RequirementInfo = (props: RequirementInfoProps) => {
   
    const { t } = useTranslation(),
        {reqNr, vocabTerm}  = props;
    
    return (
        <div className="requirementInfo">
            {reqNr && <span className="requirementNumber">{ reqNr }</span>}
            <span className={"requirementTerm " + vocabTerm}>
                {t(vocabTerm)}
            </span>
        </div>
    );
}

export { RequirementInfo }
