import React from 'react';
import { ComponentArg } from '../../types/component-arg';
import { defaultChildren } from '../generic/default-children';
import { key } from '../util/key';

function Fn(arg: ComponentArg): JSX.Element {
    const id = key(arg);
    return (<div className="fn" id={id} key={id}>{defaultChildren(arg)}</div>);
}

export { Fn };