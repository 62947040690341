import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'stream-browserify';
import React from 'react';
import { Provider } from 'react-redux'
import store from './redux/store'
import * as ReactDOMClient from 'react-dom/client'

import { App } from './App';
import * as serviceWorker from './serviceWorker';
import AppConfigurationWrapper from './AppConfigurationWrapper';

const container = document.getElementById('root')!;
const root = ReactDOMClient.createRoot(container);

root.render(
    <Provider store={store}>
        <AppConfigurationWrapper>
            <App/>
        </AppConfigurationWrapper>
    </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
