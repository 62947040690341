import { select, takeEvery, call } from '@redux-saga/core/effects';
import { standardStateStore } from '../standard-state/standard-state.store';
import { FilterDocumentState, ViewOnlyCategory } from './filter-document.state';
import { FilterDocumentActionType } from './types';

function* updateRequirementsState() {
  const standardState = standardStateStore.getState(),
    instanceRequirements = standardState.initLazyStateRequirements,
    instanceThemes = standardState.initLazyStateThemes,
    tocService = standardState.tocService,
    thisState: FilterDocumentState = yield select();

  if (thisState.filterIsApplied) {
    if (thisState.viewOnlyCategory === ViewOnlyCategory.requirements) {
      instanceRequirements.requirementTypes = new Set(thisState.selectedRequirementTypes);
      yield call([instanceRequirements, instanceRequirements.initData]);
    } else if (thisState.viewOnlyCategory === ViewOnlyCategory.themes) {
      instanceThemes.themes = thisState.selectedThemes;
      yield call([instanceThemes, instanceThemes.initData]);
    }
  }

  if (tocService) {
    tocService.documentLoad();
  }
}

const actOnTypes: FilterDocumentActionType[] = Object.values(FilterDocumentActionType);

function* filterDocumentSaga() {
  yield takeEvery(actOnTypes, updateRequirementsState);
}

export { filterDocumentSaga };
