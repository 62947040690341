import { Component } from "react";
import { ComponentArg } from "../../../types/component-arg";
import { Unsubscribe } from "redux";
import { filterDocumentStore } from "../../../redux/filter-document";
import { RequirementType } from "../../../types/requirement.type";
import { key } from "../../util/key";
import React from "react";
import { defaultChildren } from "../../generic/default-children";
import { VocabTermInfo, VocabTermInfoProps} from "../vocab-term-info";
import Requirement, { RequirementProps } from '../requirement';
import { defaultComponent } from '../../generic/default-component';
import Hidden from '../hidden';
import Category, { IsSupportedCategory } from './contentType/category';
import Name, { IsName } from './contentType/name';
import Value, { IsValue } from './contentType/value';
import Key, { IsKey } from './contentType/key';

interface NamedContentState {
    filterIsApplied: boolean;
    isMatrix: boolean;
    showVocabTerm: boolean;
    className: string;
    isAttributeElement: boolean;
}

class NamedContent extends Component<ComponentArg, NamedContentState> {

    constructor( p: ComponentArg ) {
        super( p );
        this.state = {
            filterIsApplied: false,
            isMatrix: false,
            showVocabTerm: false,
            className: '',
            isAttributeElement: false
        };
    }

    filterDocumentUnsubscribe: Unsubscribe;

    componentDidMount() {
        this.filterDocumentUnsubscribe = filterDocumentStore.subscribe( this.onFilterDocumentUpdated.bind( this ) );
        this.extractAttributes().then();
    }

    private async extractAttributes() {
        const { node } = this.props,
            a = node!.a,
            contentType = a[ 'content-type' ],
            vocab = a[ 'vocab' ],
            vocabTerm = a[ 'vocab-term' ],
            className = contentType === vocab ? contentType : `${contentType} ${vocab}`;

        let showVocabTerm = false;
        if ( vocabTerm && ( contentType === 'unit-category' || contentType === 'specification' || contentType === 'keyword' ) ) {
            showVocabTerm = true;
        }

        const isMatrix = (contentType === 'matrix');

        // named-content as child helper attribute element
        const isAttributeElement = (contentType === 'valid-from' || contentType === 'req-number');

        this.setState({           
            isMatrix,
            showVocabTerm,
            className,
            isAttributeElement
        });      
        this.onFilterDocumentUpdated();
    }

    componentWillUnmount(): void {
        this.filterDocumentUnsubscribe();
    }

    private onFilterDocumentUpdated() {
        const selectedRequirementTypesSet: Set<RequirementType> = filterDocumentStore.getState().selectedRequirementTypes,
            filterIsApplied = filterDocumentStore.getState().filterIsApplied,
            selectedRequirementTypes = Array.from( selectedRequirementTypesSet.values() );

        if ( selectedRequirementTypes.length === 5 ) {
            this.setState( { filterIsApplied: false } );
            return;
        }
        this.setState( { filterIsApplied } );
    }

    render() {

        const {
                isMatrix,
                className,
                showVocabTerm,
                isAttributeElement
            } = this.state,

            key1 = key( this.props ),
            a = this.props.node!.a;

        if (this.props.hideReq || isAttributeElement) {
            return <Hidden {...this.props} />;
        }

        if ( isMatrix ) {
            return (
                <div className={className} key={key1}>
                    {defaultChildren( this.props )}
                </div>
            );
        }

        const contentType = a['content-type']

        if (contentType === 'requirement') {
            const requirementProps: RequirementProps = {
                component: this.props,
            }

            return <Requirement {...requirementProps} />
        }

        const vocabTerm = a[ 'vocab-term' ];
        if ( showVocabTerm ) {
            const vocabTermInfoProps: VocabTermInfoProps = {
                vocabTerm: vocabTerm
            };

            return (
                <div className={className} key={key1}>
                    <VocabTermInfo { ...vocabTermInfoProps}/>
                    {defaultChildren( this.props )}
                </div>
            );
        }

        if (IsSupportedCategory(contentType)) {
            return <Category category={contentType} component={this.props} />
        }

        const vocab = a[ 'vocab' ];
        
        if (IsName(contentType)) {
            return <Name component={this.props} className={vocab} />
        }

        if (IsValue(contentType)) {
            return <Value component={this.props} vocab={vocab} />
        }

        if (IsKey(contentType)) {
            return <Key component={this.props} />
        }

        return defaultComponent(this.props);
    }
}

export { NamedContent };
