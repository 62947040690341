import React from 'react';
import * as assert from 'assert';
import { TFunction } from 'i18next';

import { ComponentArg } from '../../types/component-arg';
import { isText, MixedContent, NodeId } from '../../types/json-node.type';
import { MixedContentComponent } from './mixed-content-component';
import { key } from '../util/key';

function defaultChildren( arg: ComponentArg ): JSX.Element | null {
    assert.ok( arg.node, 'expected node' );
    return defaultChildrenMixed( arg.node!.c, key( arg ), arg.t );
}

function defaultChildrenMixed( mixed: MixedContent[], key: NodeId, t: TFunction ): JSX.Element | null {
    const length = mixed.length;
    switch ( length ) {
        case 0:
            return null;
        case 1:
            if ( !mixed[ 0 ] ) return null;
            const singleMixedContent = <MixedContentComponent
                mixedContent={mixed[ 0 ]}
                parentNodeId={key}
                siblingIdx={0} t={t}/>;
            if ( isText( mixed[ 0 ] ) ) return <span className="default-text" key={key}>{singleMixedContent}</span>;
            return singleMixedContent;
        default:
            return (
                <React.Fragment key={key}>
                    {mixed.map( ( child: MixedContent, idx: number ) =>
                        <MixedContentComponent
                            parentNodeId={key}
                            siblingIdx={idx} t={t}
                            mixedContent={child}
                            key={idx}
                        /> )}
                </React.Fragment>
            );
    }
}

export { defaultChildren, defaultChildrenMixed };
