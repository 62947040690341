import React from 'react';
import { ComponentArg } from '../../types/component-arg';
import { defaultChildren } from '../generic/default-children';
import { key } from '../util/key';

function list( arg: ComponentArg ): JSX.Element {
    const listType = arg.node?.a[ 'list-type' ];
    const listTypeClass = listType ? `list-type-${ listType }` : '';
    
    return (
        <div className={ `list ${ listTypeClass }` } key={ key( arg ) }>
            <ul>{ defaultChildren( arg ) }</ul>
        </div>
    );
}

export { list };
