import { AxiosResponse } from 'axios';
import { call, takeEvery, select, put } from 'redux-saga/effects';

import {
  RequestUrlData,
  StandardState,
  StandardStateAction,
  StandardStateActionType,
} from './standard-state.types';
import {
  initClassInstances,
  initGraphInstance,
  initGraphInstanceCollection,
} from './init-class-instances.saga';
import Auth from '../../component/authentication/auth';
import { StandardDto } from '../../types/standard-dto';
import {
  loadingSpinnerSubject,
  LoadingSpinnerNotification,
} from '../identity/loadingSpinnerSubject';
import { closeLeftMenuSubject } from '../identity/closeLeftMenuSubject';
import { filterDocumentStore } from '../filter-document';
import { FilterDocumentActionType } from '../filter-document/types';
import { dispatch2searchStore } from '../search';
import { SearchActionType } from '../search/search.reducer';
import { clearSearchInputSubject } from '../identity/clearSearchInputSubject';
import { processError } from '../../component/util/processError';
import { isPublic } from '../../component/util/checkConfig';
import store, { getAppConfiguration } from '../store';
import { setEnquiryPartial, setEnquiryPublished } from '../features';
import { queryStrings } from '../../constants/query-strings';
import { CollectionDto } from '../../types/collection-dto';

function* standardSaga() {
  yield takeEvery(
    [StandardStateActionType.callApi, StandardStateActionType.graphInstanceReady],
    forwardAction,
  );
}

function* forwardAction(action: StandardStateAction) {
  switch (action.type) {
    case StandardStateActionType.callApi:
      yield callApi(action);
      break;
    case StandardStateActionType.graphInstanceReady:
      yield initClassInstances();
      break;
  }
}

const isCollection = (
  object: StandardDto | CollectionDto,
): object is CollectionDto => {
  return 'collectionContent' in object;
};

function* callApi(action: StandardStateAction) {
  try {
    closeLeftMenuSubject.dispatch({ type: null, content: null });
    filterDocumentStore.dispatch({ type: FilterDocumentActionType.clearFilter });
    dispatch2searchStore({ type: SearchActionType.clearSearch });

    clearSearchInputSubject.dispatch({ type: null, content: null });

    const loadingSpinner: LoadingSpinnerNotification = { visible: true };
    loadingSpinnerSubject.dispatch({ type: null, content: loadingSpinner });
    const auth: Auth = yield select((state: StandardState) => state.auth);
    const response: AxiosResponse<StandardDto | CollectionDto> = yield call(
      caller,
      action.content as RequestUrlData,
      auth,
    );
    if (!isCollection(response.data)) {
      const standard = response.data as StandardDto;
      yield put({
        type: StandardStateActionType.setStandardDto,
        content: standard,
      });
      yield initGraphInstance(standard);
    } else {
      const collection = response.data as CollectionDto;
      yield put({
        type: StandardStateActionType.setCollectionDto,
        content: collection,
      });

      yield initGraphInstanceCollection(collection);
    }

    store.dispatch(
      setEnquiryPublished(response.data.productInfo.meta?.published || false),
    );
    store.dispatch(
      setEnquiryPartial(response.data.productInfo.meta?.partialEnquiry || false),
    );
  } catch (e) {
    processError(e);
  }
}

function caller(
  requestUrlData: RequestUrlData,
  auth: Auth,
): Promise<AxiosResponse<StandardDto>> {
  const nisoStsSearchParams = new URLSearchParams();
  if (requestUrlData && requestUrlData.filePath) {
    nisoStsSearchParams.append(
      queryStrings.filePath,
      encodeURIComponent(requestUrlData.filePath),
    );
  }

  if (requestUrlData && requestUrlData.isPublishPreview) {
    nisoStsSearchParams.append(queryStrings.isPublishPreview, 'true');
  }

  if (requestUrlData && requestUrlData.organization) {
    nisoStsSearchParams.append(
      queryStrings.organization,
      encodeURIComponent(requestUrlData.organization),
    );
  }

  const apiUrl = `${getAppConfiguration().standardsViewerApiUrl}/nisosts/${
    requestUrlData.productId
  }?${nisoStsSearchParams.toString()}`;

  const headers = {
    'Content-Type': 'text/plain; charset=utf-8',
  };

  if (isPublic()) {
    return auth.getPublicApiData(apiUrl, { headers: headers, responseType: 'json' });
  } else {
    return auth.getProtectedApiData(apiUrl, {
      headers: headers,
      responseType: 'json',
    });
  }
}

export { standardSaga };
