import { Dialog } from '@mui/material';
import { DialogProps } from '@mui/material/Dialog';
import React, { useEffect, useRef, useState } from 'react';

import { ImageKey } from '../../types/image/image.types';
import { ComponentArg } from '../../types/component-arg';
import { key } from '../util/key';
import { JsonNodeType } from '../../types/json-node.type';
import { GraphicCaption, GraphicCaptionWithButton } from './graphic-caption';
import { standardStateStore } from '../../redux/standard-state/standard-state.store';
import { useAppSelector } from '../../redux/store';
import { imageApi } from '../../redux/services/imageApi';
import styles from './imgModal.module.css';

interface Props extends ComponentArg {
  imageTitle: string;
  imageKey: ImageKey;
  isInsideRequirement: boolean;
  isInline: boolean;
  isinViewport(el?: HTMLElement): boolean;
}

const ImgModal = (props: Props) => {
  const { imageKey, imageTitle, isInsideRequirement, node, t } = props;
  const areAllExpanded = useAppSelector(
    (state) => state.ExpandCollapseState.areAllExpanded,
  );
  const [isImageVisible, setIsImageVisible] = useState(areAllExpanded);
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState<DialogProps['scroll']>('paper');
  const { data: base64FromComponentState } = imageApi.useGetImageQuery(imageKey);
  const imageElementRef: React.RefObject<HTMLImageElement> = useRef(null);
  const imageWrapperRef: React.RefObject<HTMLDivElement> = useRef(null);

  useEffect(() => {
    setIsImageVisible(areAllExpanded);
  }, [areAllExpanded]);

  const metadata = standardStateStore
    .getState()
    .standardDto?.productInfo?.figureMetadata?.find(
      // In authoring, imageId is the whole filename in azure with extension, while in Enquiry it is just the filename without extension
      (m) => m.fileName?.includes(imageKey.imageId),
    );

  const handleImageVisibleToggle = () => {
    setIsImageVisible(!isImageVisible);
  };

  const handleClickOpen = (scrollType: DialogProps['scroll']) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  let figCaption: JsonNodeType | undefined = undefined;
  if (node && node.c && node.c.length) {
    for (const child of node.c) {
      if (!child.x) {
        continue;
      }
      const childTag = child.x.tag;
      if (childTag === 'figcaption') {
        figCaption = child.x;
      }
    }
  }

  const key1 = key(props);

  const imageWrapperStyle = {
    '--image-width': metadata?.width ? `${metadata.width / 16}rem` : undefined,
    '--image-aspect-ratio':
      metadata?.width && metadata.height
        ? metadata.width / metadata.height
        : undefined,
  } as React.CSSProperties;

  const wrapperClassname = `${styles.imageWrapper} ${
    !base64FromComponentState ? styles.animatedBackground : ''
  }`;

  const imageWrapper = (
    <button
      type="button"
      onClick={handleClickOpen('paper')}
      className="expandImageButton"
      title={t(base64FromComponentState ? 'ExpandImageHint' : 'ImageLoading')}
      aria-expanded={open}
      disabled={!base64FromComponentState}
    >
      <div
        className={wrapperClassname}
        style={imageWrapperStyle}
        ref={imageWrapperRef}
      >
        {base64FromComponentState && (
          <img
            alt={props.imageTitle}
            id={key1}
            ref={imageElementRef}
            src={base64FromComponentState}
            key={key1}
            className={
              isInsideRequirement && !isImageVisible
                ? 'hiddenImage'
                : props.isInline
                ? 'inline'
                : ''
            }
          />
        )}
      </div>
    </button>
  );

  return (
    <>
      {isInsideRequirement ? (
        <>
          {figCaption && (
            <GraphicCaptionWithButton
              node={figCaption}
              t={t}
              onClick={handleImageVisibleToggle}
              ariaExpanded={isImageVisible}
              imageId={key1}
            />
          )}
          {imageWrapper}
        </>
      ) : (
        <>
          {imageWrapper}
          {figCaption && <GraphicCaption t={t} node={figCaption} />}
        </>
      )}
      {base64FromComponentState && (
        <Dialog
          PaperProps={{ square: true }}
          fullWidth={false}
          maxWidth={false}
          className="imageZoom"
          scroll={scroll}
          onClose={() => setOpen(false)}
          aria-labelledby={'fullImage' + key1}
          open={open}
        >
          <button
            type="button"
            onClick={() => setOpen(false)}
            className="modalCloseButton"
            aria-label={t('Close')}
          />
          <img
            id={'fullImage' + key1}
            alt={imageTitle}
            src={base64FromComponentState}
            onClick={() => setOpen(false)}
            className="fullImage"
          />
        </Dialog>
      )}
    </>
  );
};

export { ImgModal };
