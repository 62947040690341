import { UserManager, WebStorageStateStore, User } from 'oidc-client-ts';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import queryString from 'query-string';
import urlJoin from 'url-join';

import { getIdentityConfig, getMetadataOidc } from './auth-const';
import { standardStateStore } from '../../redux/standard-state/standard-state.store';
import { storageKeys } from '../../storage/storage-keys';
import { ShowErrorInstance } from '../../singleton';
import { isPublic, isEnquiryEnabled, urlToEnquiry } from '../util/checkConfig';
import { queryStrings } from '../../constants/query-strings';
import { WithRouterProps } from "../../withRouter";

class Auth {
    userManager: UserManager;
    user: User;

    constructor() {
        if (!isPublic()) {
            this.userManager = new UserManager({
                ...getIdentityConfig(),
                userStore: new WebStorageStateStore({ store: window.localStorage }),
                metadata: {
                    ...getMetadataOidc()
                }
            });

            this.userManager.events.addSilentRenewError( e => {
                ShowErrorInstance.Fn( e.message );
            } );
        }
    }

    startAuthentication = (router: WithRouterProps, urlQueryString?: string | undefined): Promise<void> => {
        const storageService = standardStateStore.getState().storageService!;
        storageService.writeToStorage(storageKeys.REDIRECT_ON_LOGIN, router.location);

        let externalClient = '';
        if (urlQueryString) {
            const queryStringValues = queryString.parse(urlQueryString);
            if (queryStringValues) {
                var externalClientValue = queryStringValues[queryStrings.externalClient];
                if (externalClientValue) {
                    externalClient = externalClientValue.toString();
                }
            }
        }

        return this.userManager.signinRedirect({
            extraQueryParams: {
                externalClient: externalClient,
                registerPageUrl: isEnquiryEnabled() ? `${urlJoin(urlToEnquiry(), 'register')}` : '',
                resendActivationPageUrl: isEnquiryEnabled() ? `${urlJoin(urlToEnquiry(), 'resend-activation-link')}` : '',
                originalFullReturnUrl: window.location.href
            }
        });
    };

    logOut = (): Promise<void> => {
        return this.userManager.signoutRedirect();
    };

    completeAuthentication = (router: WithRouterProps): Promise<void> => {
        return this.userManager.signinRedirectCallback().then( user => {
            this.user = user;
            const storageService = standardStateStore.getState().storageService!;
            const redirectLocation = storageService.readFromStorage( storageKeys.REDIRECT_ON_LOGIN );
            const url = redirectLocation && redirectLocation !== 'undefined' ? redirectLocation : '/';
            router.navigate(url);
            storageService.removeFromStorage( storageKeys.REDIRECT_ON_LOGIN );
        } );
    };

    isLoggedIn = (): boolean => {
        return this.user != null && !this.user.expired;
    };

    getAccessToken = (): Promise<string> => {
        return this.userManager.getUser().then( ( data: User | null ) => {
            return data ? data.access_token : '';
        } );
    };

    getProtectedApiData = ( url: string, config: AxiosRequestConfig ): Promise<AxiosResponse<any>> =>
        this.getAccessToken().then( ( userToken: string ) => {
            axios.defaults.headers.common[ 'Authorization' ] = `Bearer ${userToken}`;
            axios.defaults.headers.common[ 'Allow-Control-Allow-Origin' ] = '*';
            return axios.get( url, config );
        } );

    postProtectedApiData = ( url: string, data: any ): Promise<AxiosResponse<any>> =>
        this.getAccessToken().then( ( userToken: string ) => {
            axios.defaults.headers.common[ 'Authorization' ] = `Bearer ${userToken}`;
            axios.defaults.headers.common[ 'Allow-Control-Allow-Origin' ] = '*';

            const config: AxiosRequestConfig = {
                headers: {'Content-Type': 'application/json; charset=utf-8'}
            };

            return axios.post( url, data, config );
        } );

    getPublicApiData = ( url: string, config: any ): Promise<AxiosResponse<any>> => {
        axios.defaults.headers.common[ 'Allow-Control-Allow-Origin' ] = '*';
        return axios.get( url, config );
    }

    setBearerToken = async (headers: Headers) => {
      const userToken = await this.getAccessToken();
      headers.set("Authorization", `Bearer ${userToken}`);
    }
}

export default Auth;
