import { Component } from "react";
import { RequestUrlData, StandardStateActionType } from "../redux/standard-state/standard-state.types";
import { standardStateStore } from "../redux/standard-state/standard-state.store";
import { Unsubscribe } from "redux";
import { productInfoLoadedSubject } from "../redux/identity/productInfoLoadedSubject";
import loadable from '@loadable/component';
import Auth from "./authentication/auth";
import { History } from 'history';
import React from "react";
import queryString from 'query-string';
import i18n from "../i18n/i18n";
import { storageKeys } from '../storage/storage-keys';
import { isPublic } from './util/checkConfig';
import Favicon from './util/favicon';
import { getCustomerCode } from './util/checkConfig';
import { customerCodes } from "../constants/customer-codes";
import store from '../redux/store';
import { setSelection } from '../redux/features';
import NekFaq from './NekFaq';
import { WithRouterProps } from "../withRouter";

const StandardsViewer = loadable( () => import('../component/standards-viewer') );
const StandardsViewerVegvesen = loadable( () => import('../component/standards-viewer.vegvesen') );
const StandardsViewerSnv = loadable( () => import('../component/standards-viewer.snv') );
const StandardsViewerNsai = loadable( () => import('../component/standards-viewer.nsai') );
const StandardsViewerSo = loadable( () => import('../component/standards-viewer.so') );

export interface ApiCallerProps {
    auth: Auth;
    history: History;
    router: WithRouterProps;
    id?: string;
    fileParameters?: string;
    languageCode?: string;
    isSelection?: boolean;
    isPublishPreview?: boolean;
}

export interface ApiCallerState {
    isProductLoaded: boolean;
    customCssClientCode?: string;
    isPdf?: boolean;
    sdo?: string;
    standardReference?: string;
}

class ApiCaller extends Component<ApiCallerProps, ApiCallerState> {

    readonly productId: string;
    readonly fileParameters?: string;
    readonly languageCode?: string;
    readonly isSelection?: boolean;
    readonly isPublishPreview?: boolean;

    constructor( p: ApiCallerProps ) {
        super( p );

        let location = this.props.router.location;
        let {id, languageCode, parameter} = this.props.router.params;

        this.productId = id ?? '';
        this.fileParameters = location.search;
        this.languageCode = languageCode;
        this.isSelection = parameter === 'selection';
        this.isPublishPreview = parameter === 'preview';

        this.state = { isProductLoaded: false, customCssClientCode: '', isPdf: false, sdo: '', standardReference: '' };
    }

    private productInfoUnsubscribe?: Unsubscribe;

    componentDidMount(): void {
        if (this.languageCode) {
            i18n.changeLanguage( this.languageCode ).then();
            const storageService = standardStateStore.getState().storageService!;
            storageService.writeToStorage( storageKeys.LANGUAGE_CODE, this.languageCode );
        }
        this.authenticate();
        this.productInfoUnsubscribe = productInfoLoadedSubject.subscribe( this.updateClientCss.bind( this ) );

        if (this.isSelection) {
			store.dispatch(setSelection(true));
		}

        this.callApi();
    }

    componentDidUpdate( prevProps: Readonly<ApiCallerProps>, prevState: Readonly<ApiCallerState>, snapshot?: any ): void {
        this.authenticate();
        if ( prevProps.id !== this.props.id ||  prevProps.fileParameters !== this.props.fileParameters  ) {
            this.callApi();
        }
    }

    componentWillUnmount(): void {
        this.productInfoUnsubscribe!();
    }

    render() {
        const isAuthenticated = this.props.auth.isLoggedIn() || isPublic();

        const { isProductLoaded, customCssClientCode } = this.state;
        if (!isAuthenticated || !isProductLoaded || !customCssClientCode) {
            return null;
        }

        return this.getStandardsViewer(customCssClientCode);
    }

    private authenticate(): void {
        const auth = this.props.auth;

        if (!isPublic() && !auth.isLoggedIn() ) {
            auth.startAuthentication(this.props.router, this.fileParameters).then();
        }
    }

    private updateClientCss() {
        const graph = standardStateStore.getState().graphService;
        if ( graph ) {
            const productInfo = graph.productInfo;

            if ( productInfo ) {
                let customCssClientCode = getCustomerCode(productInfo.customCssClientCode);
                this.setState( { isProductLoaded: true, customCssClientCode: customCssClientCode, isPdf: productInfo.isPdf, sdo: productInfo.meta?.sdo, standardReference: productInfo.meta?.standardReference } );
            }
        }
    }

    private callApi() {
        const auth = this.props.auth;
        if (!isPublic() && !auth.isLoggedIn() ) {
            return;
        }

        let filePath: string | undefined = undefined;
        let organization: string | undefined = undefined;

        if (this.fileParameters) {
            const queryStringValues = queryString.parse(this.fileParameters);
            if (queryStringValues) {
                if  (queryStringValues.filePath) {
                    filePath = queryStringValues.filePath.toString();
                }

                if  (queryStringValues.organization) {
                    organization = queryStringValues.organization.toString();
                }
            }
        }

        const requestUrlData: RequestUrlData = {
            productId: this.productId,
            filePath: filePath,
            isPublishPreview: this.isPublishPreview,
            organization: organization
        };
        standardStateStore.dispatch(
            {
                type: StandardStateActionType.callApi,
                content: requestUrlData
            }
        );
    }

    private getStandardsViewer(customCssClientCode: string): JSX.Element | null {

        let isPrint = false;
        if (this.fileParameters) {
            const queryStringValues = queryString.parse(this.fileParameters)
            if (queryStringValues && queryStringValues.isPrint) {
                isPrint = true;
            }
        }

        const { isPdf, sdo, standardReference } = this.state;
        const { history } = this.props;


        let standardsViewerHtml;

        // noinspection JSRedundantSwitchStatement
        switch ( customCssClientCode ) {
            case customerCodes.nsai:
                standardsViewerHtml = (
                    <StandardsViewerNsai
                        productId={this.productId}
                        isPdf={isPdf}
                        history={history}
                        isPrint={isPrint}
                    />
                );
                break;

            case customerCodes.snv:
                standardsViewerHtml = (
                    <StandardsViewerSnv
                        productId={this.productId}
                        isPdf={isPdf}
                        history={history}
                        isPrint={isPrint}
                    />
                );
                break;
            case customerCodes.vegvesen:
            case customerCodes.svv:
                standardsViewerHtml = (
                    <StandardsViewerVegvesen
                        productId={this.productId}
                        isPdf={isPdf}
                        history={history}
                        isPrint={isPrint}
                    />
                );
                break;
            case customerCodes.so:
                standardsViewerHtml = (
                    <StandardsViewerSo
                        productId={this.productId}
                        isPdf={isPdf}
                        history={history}
                        isPrint={isPrint}
                    />
                );
                break;
            default:
                standardsViewerHtml = (
                    <StandardsViewer
                        productId={this.productId}
                        isPdf={isPdf}
                        history={history}
                        isPrint={isPrint}
                    />
                );
                break;
        }

        const showNekFaq = (sdo && standardReference && sdo.toLowerCase() === 'nek');

        return (
            <>
                {/* Load favicon again after API call in case customer code has changed. */}
                <Favicon customer={customCssClientCode} />
                {showNekFaq && <NekFaq productCode={standardReference} />}
                {standardsViewerHtml}
            </>
        );
    }
}

export { ApiCaller };
