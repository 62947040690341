import { JsonNodeType } from '../../types/json-node.type';
import { GraphService } from '../../graph/graph.service';
import { LazyState } from '../../component/lazy/lazy.state';
import { TocService } from '../../component/lazy/toc.service';
import { UpdateSectionService } from '../../component/lazy/update-section.service';
import { Action } from 'redux';
import Auth from '../../component/authentication/auth';
import { StorageService } from '../../storage/storage.service';
import { Base64 } from '../../types/image/image.types';
import { InitLazyStateRequirements } from '../../component/lazy/init-state/init-lazy-state.requirements';
import { SearchService } from '../../component/side-bar/search-document/search.service';
import { StandardDto } from '../../types/standard-dto';
import { InitLazyStateThemes } from '../../component/lazy/init-state/init-lazy-state.themes';
import { CollectionDto } from '../../types/collection-dto';

export enum StandardStateActionType {
  setAuth = 'setAuth',
  callApi = 'callApi',
  setStandardDto = 'setStandardDto',
  setCollectionDto = 'setCollectionDto',
  graphInstanceReady = 'graphInstanceReady',
  setGraphService = 'setGraphService',
  setSearchService = 'setSearchService',
  setTocService = 'setTocService',
  setStorageService = 'setStorageService',
  setPdfContentBase64 = 'setPdfContentBase64',
  setMessageType = 'setMessageType',
  setInitLazyStateRequirements = 'setInitLazyStateRequirements',
  setInitLazyStateThemes = 'setInitLazyStateThemes',
}

export class StandardState {
  constructor() {
    this.lazyStateStandard.send2workerOnDirty = true;
  }

  auth?: Auth;
  standardDto?: StandardDto;
  collectionDto?: CollectionDto;
  lazyStateStandard: LazyState = new LazyState();
  lazyStateTables: LazyState = new LazyState();
  lazyStateFigures: LazyState = new LazyState();
  lazyStateRequirements: LazyState = new LazyState();
  lazyStateThemes: LazyState = new LazyState();
  lazyStateCommentables: LazyState = new LazyState();
  initLazyStateRequirements: InitLazyStateRequirements;
  initLazyStateThemes: InitLazyStateThemes;
  requestUrlData: RequestUrlData;
  graphService?: GraphService;
  searchService?: SearchService;
  tocService?: TocService;
  storageService?: StorageService;
  pdfContentBase64?: Base64;
  messageType?: string;
}

export type ProductId = string;

export interface RequestUrlData {
  productId: ProductId;
  filePath?: string;
  isPublishPreview?: boolean;
  organization?: string;
}

export interface StandardStateAction extends Action {
  type: StandardStateActionType;
  content?:
  | Auth
  | StandardDto
  | CollectionDto
  | JsonNodeType
  | GraphService
  | SearchService
  | RequestUrlData
  | TocService
  | UpdateSectionService
  | StorageService
  | Base64
  | InitLazyStateRequirements
  | InitLazyStateThemes;
}
