import { configureStore } from '@reduxjs/toolkit';
import { rootReducer } from './reducer';
import { appConfigurationApi } from './services/appConfigurationApi';
import { AppConfiguration } from './slices/configurationSlice';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { imageApi } from './services/imageApi';


const composeEnhancers = {
   realtime: true,
   name: 'Your Instance Name',
   hostname: 'localhost',
   port: 8000 // the port your remotedev server is running at
 }

const store = configureStore({
   reducer: rootReducer,
   middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(
        appConfigurationApi.middleware,
        imageApi.middleware
      ),
   devTools: composeEnhancers
})

export const getAppConfiguration = (): AppConfiguration => {
   return store.getState().configuration;
}

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof rootReducer>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store
